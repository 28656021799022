import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"   viewBox="0 0 2560.000000 2560.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,2560.000000) scale(0.100000,-0.100000)">

<path d="M8685 15283 c-293 -26 -618 -113 -871 -233 -195 -93 -436 -250 -579
-379 -91 -81 -245 -241 -245 -254 0 -12 240 -257 252 -257 3 0 59 53 125 118
117 118 263 235 378 304 220 133 486 232 728 272 220 37 322 38 4337 33 2153
-3 3950 -8 3993 -12 42 -4 77 -11 77 -16 0 -5 -5 -9 -10 -9 -6 0 -71 -60 -145
-134 -130 -128 -135 -135 -135 -175 0 -38 6 -48 96 -144 53 -56 106 -116 118
-133 11 -17 54 -78 94 -135 148 -208 290 -533 347 -789 59 -265 82 -612 54
-802 -40 -270 -76 -429 -137 -593 -41 -111 -148 -332 -210 -430 -52 -83 -200
-286 -226 -310 -6 -6 -39 -40 -73 -77 -91 -96 -89 -115 23 -234 22 -22 62 -61
91 -86 44 -39 49 -48 35 -55 -9 -4 -1835 -9 -4057 -11 -3687 -2 -4049 -1
-4150 14 -295 44 -436 85 -661 194 -224 108 -382 217 -544 377 l-105 103 -138
-136 c-75 -74 -137 -138 -137 -142 0 -4 35 -44 78 -88 382 -395 842 -633 1412
-731 100 -17 313 -18 4615 -18 4236 0 4516 1 4615 17 110 19 327 69 395 93
290 99 509 211 710 363 80 61 220 180 290 247 38 37 187 214 244 289 237 318
394 701 458 1116 25 163 25 569 0 725 -91 567 -378 1102 -790 1474 -374 337
-790 533 -1317 623 l-135 23 -4430 1 c-2436 0 -4448 -1 -4470 -3z m9050 -753
c3 -6 -1 -7 -9 -4 -18 7 -21 14 -7 14 6 0 13 -4 16 -10z m235 -184 c19 -24 6
-19 -26 10 -19 17 -22 22 -8 16 11 -6 26 -18 34 -26z m162 -170 c10 -19 8 -18
-16 3 -14 14 -26 29 -26 34 0 13 29 -12 42 -37z m252 -1435 c3 -5 -1 -14 -8
-20 -12 -10 -13 -8 -9 8 6 23 9 25 17 12z"/>
<path d="M6605 14283 c-263 -269 -482 -694 -555 -1078 -33 -174 -49 -483 -32
-595 6 -36 18 -114 27 -175 55 -360 222 -724 475 -1033 56 -69 140 -155 169
-174 52 -35 63 -29 252 159 211 208 211 210 133 297 -114 127 -109 120 -185
231 -129 186 -216 406 -251 635 -16 109 -16 381 0 490 22 141 84 335 148 462
31 61 140 231 175 273 13 16 45 52 71 80 64 69 79 100 66 135 -5 16 -86 103
-179 194 -161 158 -171 166 -209 166 -35 0 -46 -7 -105 -67z"/>
<path d="M16140 14133 c-118 -118 -169 -176 -172 -196 -5 -24 4 -40 56 -100
33 -40 63 -74 66 -77 10 -9 66 -84 104 -138 41 -59 134 -239 165 -323 121
-316 120 -756 -2 -1079 -27 -71 -109 -238 -137 -279 -9 -14 -33 -51 -53 -81
-20 -30 -48 -68 -63 -85 -15 -16 -47 -51 -71 -77 -33 -37 -43 -55 -43 -82 0
-31 16 -51 168 -205 173 -176 187 -185 235 -160 25 14 91 85 160 173 26 33 56
71 66 83 75 89 208 339 273 512 79 211 138 527 138 742 0 136 -44 444 -81 564
-42 137 -73 224 -108 297 -23 47 -41 88 -41 91 0 7 -100 179 -131 223 -16 24
-29 46 -29 50 0 3 -8 14 -18 23 -10 9 -28 30 -40 48 -38 53 -64 83 -143 165
-58 61 -82 78 -103 78 -23 0 -59 -31 -196 -167z"/>
<path d="M15555 13548 c-167 -169 -195 -202 -195 -229 0 -10 21 -42 47 -71
240 -270 258 -638 47 -917 -27 -35 -55 -72 -63 -82 -7 -11 -11 -29 -7 -43 3
-13 82 -101 175 -195 194 -196 201 -199 268 -133 96 96 232 317 279 453 55
162 59 190 59 434 l0 230 -33 110 c-59 194 -175 396 -301 526 -98 100 -92 102
-276 -83z"/>
<path d="M8094 13647 c-40 -15 -77 -46 -95 -77 -12 -21 -15 -173 -20 -890 -3
-476 -10 -882 -14 -903 l-7 -37 223 2 c218 3 224 4 220 23 -2 11 -6 158 -8
328 l-4 307 156 0 157 0 -5 -302 c-2 -167 -8 -315 -12 -331 l-7 -27 223 2 224
3 -4 25 c-2 14 -7 417 -10 896 l-6 870 -27 42 c-53 79 -38 77 -523 79 -318 2
-438 -1 -461 -10z m590 -413 c14 -14 16 -46 14 -237 l-3 -222 -153 0 -153 0 1
230 c0 168 4 231 13 237 7 4 69 8 139 8 102 0 130 -3 142 -16z"/>
<path d="M9345 13651 c-3 -6 -1 -29 5 -53 10 -41 9 -1766 -1 -1828 l-4 -25
516 0 515 0 42 27 c78 52 77 45 77 464 l0 370 -27 41 c-17 25 -43 48 -67 59
l-40 18 37 16 c49 23 78 56 91 105 15 54 15 607 0 675 -13 62 -57 110 -116
128 -50 15 -1018 17 -1028 3z m729 -417 c13 -13 16 -42 16 -160 0 -193 8 -185
-183 -182 -78 2 -141 6 -139 11 1 4 2 84 2 177 l0 170 144 0 c118 0 147 -3
160 -16z m-4 -704 c12 -7 16 -41 18 -178 3 -146 1 -172 -13 -186 -14 -13 -40
-16 -161 -16 l-144 0 0 195 0 195 143 0 c78 0 149 -5 157 -10z"/>
<path d="M10728 13655 c-3 -3 -1 -27 4 -54 9 -50 7 -1814 -2 -1841 -4 -13 56
-15 498 -18 475 -2 506 -1 546 17 24 10 56 36 72 57 l29 37 0 843 0 842 -30
43 c-55 79 -49 78 -616 78 -273 1 -498 -1 -501 -4z m730 -417 c9 -9 12 -144
12 -544 0 -476 -2 -533 -16 -538 -9 -3 -81 -6 -160 -6 l-144 0 0 550 0 550
148 0 c102 0 152 -4 160 -12z"/>
<path d="M13565 13650 c-4 -6 -1 -34 5 -63 13 -60 9 -1773 -4 -1819 l-8 -28
522 0 c494 0 522 1 514 18 -4 9 -9 99 -12 199 l-4 183 -294 2 -295 3 1 712 c0
392 3 733 6 758 l7 45 -216 0 c-135 0 -218 -4 -222 -10z"/>
<path d="M12106 13613 c4 -16 10 -417 13 -893 4 -630 9 -870 18 -885 14 -26
62 -69 90 -84 29 -15 912 -15 965 -1 41 12 91 50 111 84 9 16 13 230 18 886 3
476 9 877 13 893 l8 27 -223 -2 c-194 -3 -223 -5 -219 -18 3 -8 6 -340 8 -737
l3 -722 -23 -6 c-13 -3 -97 -4 -188 -3 l-165 3 -7 40 c-9 53 -3 1359 7 1408
l7 37 -222 0 -222 0 8 -27z"/>
<path d="M14799 13046 c-76 -27 -148 -101 -168 -171 -14 -52 -14 -148 1 -196
14 -47 90 -129 144 -157 121 -61 288 -16 358 97 64 104 70 184 21 284 -26 52
-42 71 -105 120 -46 37 -180 49 -251 23z"/>
<path d="M8655 9673 c0 -371 2 -429 15 -434 8 -4 104 -7 212 -8 218 -2 260 6
278 54 14 37 13 723 -1 764 -6 17 -19 36 -29 41 -11 6 -117 10 -247 10 l-228
0 0 -427z m333 -5 l-3 -253 -72 -3 -73 -3 0 256 0 255 75 0 75 0 -2 -252z"/>
<path d="M9277 9668 l2 -433 68 -3 c37 -2 78 0 90 3 l23 5 0 430 0 430 -92 0
-93 0 2 -432z"/>
<path d="M9603 10088 c-40 -20 -46 -89 -39 -462 4 -187 11 -347 16 -357 18
-32 68 -39 294 -39 122 0 230 3 241 6 20 5 20 12 18 212 -2 114 -5 217 -8 228
-7 33 -51 45 -156 42 l-94 -3 0 -75 0 -75 43 -3 42 -3 0 -55 c0 -85 -4 -88
-107 -92 -69 -3 -92 0 -101 11 -9 11 -12 79 -10 253 l3 239 97 3 c96 3 98 2
104 -22 3 -13 4 -43 2 -67 l-3 -44 90 0 90 0 3 75 c5 117 -6 197 -31 220 -19
19 -36 20 -246 19 -138 0 -235 -5 -248 -11z"/>
<path d="M10257 9667 l2 -432 88 0 88 0 3 433 2 432 -92 0 -93 0 2 -433z"/>
<path d="M10527 10093 c-34 -4 -38 -7 -33 -26 3 -12 6 -46 6 -74 l0 -53 90 0
90 0 0 -350 0 -349 34 -6 c18 -4 59 -5 90 -3 l57 3 -1 353 0 352 90 0 c49 0
90 3 91 8 0 4 3 37 5 73 3 57 1 67 -14 71 -23 6 -457 7 -505 1z"/>
<path d="M11159 10081 l-24 -19 -5 -409 c-3 -266 -2 -411 5 -416 6 -4 48 -6
94 -5 l83 3 -1 153 -2 153 73 -3 73 -3 0 -147 c0 -169 -8 -158 108 -150 l77 5
0 387 c0 399 -4 441 -40 460 -11 6 -107 10 -218 10 -182 0 -202 -2 -223 -19z
m299 -273 l3 -108 -76 0 -75 0 0 110 0 111 73 -3 72 -3 3 -107z"/>
<path d="M11747 9668 l2 -433 226 -2 226 -2 -3 87 -3 87 -132 3 -133 3 0 344
0 345 -92 0 -93 0 2 -432z"/>
<path d="M12680 10093 c-63 -10 -72 -15 -86 -48 -17 -41 -20 -279 -4 -336 6
-21 21 -44 33 -52 13 -8 85 -30 160 -48 l137 -34 0 -77 c0 -43 -3 -78 -7 -78
-16 -1 -128 -2 -141 -1 -10 1 -13 16 -11 54 l3 52 -92 3 -92 3 0 -120 c0 -118
0 -119 30 -151 l29 -31 205 3 c201 3 205 3 230 27 l26 24 0 187 c0 245 8 233
-205 286 l-130 32 -3 61 -3 61 77 0 76 0 -6 -45 -7 -45 95 0 96 0 0 103 c0
125 -16 160 -76 170 -41 7 -291 7 -334 0z"/>
<path d="M13207 9667 l2 -432 230 -3 231 -2 0 77 c0 43 -5 85 -10 93 -8 12
-33 15 -140 13 l-130 -3 0 95 0 94 118 3 117 3 0 85 0 85 -117 3 -118 3 0 69
0 70 140 0 140 0 0 90 0 90 -232 0 -233 0 2 -433z"/>
<path d="M13795 10091 l-50 -6 0 -420 c0 -268 4 -423 10 -427 6 -4 47 -8 93
-8 l82 0 0 170 0 170 26 0 c20 0 28 -8 41 -37 8 -21 35 -80 58 -130 24 -51 46
-107 49 -124 2 -17 7 -34 11 -37 4 -4 49 -9 101 -10 90 -4 94 -3 94 17 0 18
-47 129 -81 194 -5 9 -20 42 -34 73 l-24 55 33 14 c49 21 56 52 55 249 0 202
-8 236 -59 253 -36 12 -310 15 -405 4z m283 -269 l-3 -87 -72 -3 -73 -3 0 89
c0 49 2 91 4 93 2 2 36 2 76 1 l71 -4 -3 -86z"/>
<path d="M14325 10083 c18 -42 155 -756 155 -809 l0 -34 135 0 135 0 0 45 c0
26 13 114 30 198 16 84 41 215 55 292 27 146 53 278 62 308 4 15 -5 17 -95 17
l-99 0 -7 -62 c-3 -35 -10 -88 -15 -118 -6 -30 -19 -109 -30 -175 -12 -66 -21
-133 -21 -150 0 -33 -14 -62 -23 -49 -3 5 -8 32 -12 59 -4 28 -17 115 -30 195
-13 80 -26 172 -30 205 -11 102 -3 95 -117 95 -92 0 -99 -1 -93 -17z"/>
<path d="M14985 9670 c0 -495 -13 -443 110 -438 l70 3 3 433 2 432 -92 0 -93
0 0 -430z"/>
<path d="M15306 10079 l-26 -20 0 -387 c1 -424 -1 -415 61 -432 55 -16 354
-13 394 4 42 18 43 24 47 194 l4 132 -93 0 -94 0 7 -63 c8 -81 -5 -97 -79 -97
-29 0 -56 4 -62 8 -6 4 -9 98 -7 255 l3 247 69 0 c81 0 86 -6 75 -101 l-7 -59
96 0 96 0 -6 128 c-7 164 -11 190 -34 202 -11 6 -103 10 -218 10 -186 0 -201
-1 -226 -21z"/>
<path d="M15900 9671 c0 -382 2 -430 16 -435 20 -8 392 -8 422 0 22 6 23 10
20 88 l-3 81 -135 5 -135 5 -3 93 -3 92 121 0 120 0 0 90 0 90 -120 0 -120 0
0 70 0 70 140 0 140 0 0 90 0 90 -230 0 -230 0 0 -429z"/>
<path d="M16525 10096 c-5 -3 -22 -7 -36 -10 -42 -9 -49 -42 -49 -220 0 -150
2 -165 21 -189 22 -29 87 -52 226 -82 57 -12 84 -23 87 -34 3 -9 1 -45 -2 -81
l-7 -65 -60 -1 c-98 -1 -99 0 -91 62 l6 54 -93 0 -94 0 -1 -110 c-3 -187 6
-193 285 -188 176 3 182 4 207 27 l26 24 0 190 0 190 -33 31 c-28 26 -53 36
-158 59 -68 16 -130 31 -137 33 -9 3 -12 21 -10 61 l3 58 56 5 c86 8 91 5 87
-43 l-3 -42 94 -3 93 -3 -4 115 c-4 165 2 160 -226 163 -97 1 -181 1 -187 -1z"/>
</g>
</svg>


    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>
}
